import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { fetchWebConfig } from 'service/api';
import swal from 'sweetalert';
import { LoadingButton } from '@mui/lab';

function WebConfigIndex() {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState({
    logo : "",
    site :"" , 
    address : "",
    currency : ""
  })
  const [logoPreview, setLogoPreview] = useState("")

  useEffect(() => {
    async function fetchData(){
      const res = await fetchWebConfig()
      if (res?.status === 200) {
        setInputs({
          logo : "",
          site : res.data.site_name,
          address : res.data.address,
          currency : res.data.currency,
        });
        setLogoPreview(res.data.logo)
      }
    }

    fetchData()
  }, [])
  

  const handleLogoChange = (e) => {
    const file =  e.target.files[0]
    setLogoPreview(URL.createObjectURL(file))
    setInputs({...inputs, logo : file})
    
  };

  const handleChangeText = (e) => {
    const {name, value} = e.target
    setInputs({...inputs,[name] : value})
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    let formData = new FormData();
    formData.append('logo', inputs.logo);
    formData.append('site', inputs.site);
    formData.append('address', inputs.address);
    formData.append('currency', inputs.currency);
    
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_URL_API_GATEWAY_GOLANG}/cms/web-config`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${Cookies.get('oms_token')}`,
      }
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error(error);
      });
    if (res.status == 200) {
      const inputLogo = document.getElementById("inputLogo")
      inputLogo.value = null
      swal('Success', "Berhasil memperbarui configurasi website", 'success');
    } else {
      swal('Oops', res.message, 'error');
    }

    setLoading(false)
  }
  
  return (
    <div className='p-6 '>
      <form className="max-w-lg mx-auto gap-5 bg-white rounded p-6" onSubmit={onSubmit}>  
          <div className="mb-5 grid gap-2">
            <label for="email" className="block mb-2 text-sm font-medium text-gray-900">Site Logo</label>
            {logoPreview && (
                <div>
                    <img src={logoPreview} alt="Profile Preview" className='w-full h-28 object-contain' />
                </div>
            )}
            <input
              accept="image/*"
              id="inputLogo"
              onChange={handleLogoChange}
              type="file"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div className="mb-5">
            <label for="site" className="block mb-2 text-sm font-medium text-gray-900">Site Name</label>
            <input type="text" id="site" name='site' value={inputs.site} onChange={handleChangeText} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
          </div>
          <div className="mb-5">
            <label for="address" className="block mb-2 text-sm font-medium text-gray-900">Address</label>
            <textarea id="address" name='address' value={inputs.address} onChange={handleChangeText} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required rows={4} ></textarea>
          </div>
          <div className="mb-5">
            <label for="currency" className="block mb-2 text-sm font-medium text-gray-900">Currency</label>
            <input type="number" name='currency' inputMode='numeric' value={inputs.currency} onChange={handleChangeText} id="currency" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
          </div>
          <LoadingButton fullWidth variant='contained' disableElevation type="submit" disabled={loading}>{loading ? "Loading..." : "Submit"}</LoadingButton>
      </form>
    </div>
  )
}

export default WebConfigIndex