import {
  Box,
  CircularProgress,
  Fade,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { get_installment_init, get_rincian_cicilan, submitManualPayment } from 'service/api';
import swal from 'sweetalert';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import styled from '@emotion/styled';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { getUser } from 'helpers/parseJWT';
import Cookies from 'js-cookie';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};

const Input = styled('input')({
  display: 'none',
});

const ManualPayment = ({ data, status, id_so, id_group, totalPrice, setUpdate }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setDestAccount('')
    setImage('')
    setCicilan('0')
    setOpen(false)
  };
  
  const [totalOrder, setTotalOrder] = useState(totalPrice);
  const [minInstallment, setMinInstallment] = useState(100);
  const [destAccount, setDestAccount] = useState('');
  const [image, setImage] = useState('');
  const [rincianCicilan, setRincianCicilan] = useState({});
  const [cicilanPertama, setCicilanPertama] = useState(0);
  const [cicilanKedua, setCicilanKedua] = useState(0);
  const [cicilan, setCicilan] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   const cicilan_pertama = (~~cicilan / 100) * totalPrice
  //   setCicilanPertama(cicilan_pertama)
  //   setCicilanKedua(totalPrice - cicilan_pertama)
  // }, [cicilan])

  const handleOpen = async () => {
    // const resp = await get_installment_init(id_group)
    // if (resp.status === 200) {
    //   setTotalOrder(resp?.data?.total_order_group)
    //   setMinInstallment(resp?.data?.min_installment)
    // }
    
    if(status === "installment") {
      const response = await get_rincian_cicilan(id_group)
      if (response.status === 200) {
        setRincianCicilan(response.data)
        setOpen(true)
      }
    } else {
      setOpen(true)
    }
  }

  const manualPayment = async (idOrder) => {
    if ((destAccount.trim().length !== 0 && destAccount.trim() === "WhatsApp") || (destAccount.trim().length !== 0 && destAccount.trim() !== "WhatsApp" && image.trim() !== '')) {
      const body = JSON.stringify({
        id_group: id_group,
        id_so: id_so,
        // totalPrice,
        image: destAccount !== 'WhatsApp' ? image : null,
        destination_account: destAccount,
        percentage: ~~cicilan
      });
      
      setIsLoading(true);
      const response = await submitManualPayment(body);
      if (response?.status === 200) {
        swal('Success', 'Manual payment created successfully!', 'success');
        setUpdate((prev) => !prev);
        setImage('');
        handleClose();
        setIsLoading(false);
      } else {
        swal('Oops', response.message, 'error');
        setIsLoading(false);
      }
    } else {
      swal('Oops', 'Please Completed all field !', 'error');
    }
  };

  const submitImage = (e) => {
    if (e.target.files[0].size > 3000000) {
      swal('Oops', 'Image size over 3MB', 'error');
      return;
    }
    let formData = new FormData();
    const randomNumber = Math.floor((Math.random() * 999999) + 100000)
    const newName = `manual_payment_proof_${randomNumber}.jpg`;
    formData.append('gambar', e.target.files[0], newName);
    fetch(`${process.env.REACT_APP_URL_API_IMAGE_UPLOAD}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${Cookies.get('oms_token')}`,
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setImage(data.file);
        } else {
          swal('Oops', data.message, 'error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const set_Cicilan = (value) => {
  //   setCicilan(value)
  //   calculateCicilan(value)
  // }

  // const calculateCicilan = async (value) => {
  //   const response = await calculate_cicilan(id_group, value)
  //   if (response.status === 200) {
  //     setCicilanPertama(response.data.installment_1)
  //     setCicilanKedua(response.data.installment_2)
  //   } else {
  //     setCicilan(0)
  //     setCicilanPertama(0)
  //     setCicilanKedua(0)
  //     swal('Failed', 'Fail to calculate instalment. Please try again!', 'error');
  //   }
  // }

  const persentaseCicilanPertama = [40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95]

  return (
    <div>
      <div
        className={`text-sm py-2 px-3 rounded-md text-center  ${getUser().roles?.includes('admin') && ['unpaid', 'installment'].includes(status) ? 'bg-blue-500 cursor-pointer' : 'bg-gray-300 cursor-not-allowed'} text-white`}
        onClick={() => !getUser().roles?.includes('admin') || !['unpaid', 'installment'].includes(status) ? false : handleOpen()}>
        Manual Payment
      </div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <Box sx={style} className='overflow-y-scroll variant-scroll relative'>
            <div className='flex justify-end absolute top-1 right-2'>
              <IconButton onClick={handleClose} style={{ textAlign: 'right' }}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className='flex flex-col space-y-3'>
              <Typography
                id='transition-modal-title'
                variant='h6'
                component='h2'>
                Manual Payment
              </Typography>

              <div className='w-full space-y-1'>
                <div className='flex'>
                  <p className='w-1/3'>ID Group :</p>{' '}
                  <p className='w-2/3 font-semibold'>{id_group}</p>
                </div>
                <div className='flex'>
                  <p className='w-1/3'>ID SO :</p>{' '}
                  <p className='w-2/3 font-semibold'>{id_so}</p>
                </div>
                <div className='flex'>
                  <p className='w-1/3'>Total Price :</p>
                  <p className='w-2/3  font-semibold'>
                    Rp. {totalOrder.toLocaleString('id-ID')}
                  </p>
                </div>
                <div className='flex'>
                  <label className='w-1/3'>Destination Account :</label>
                  <select
                    onChange={(e) => setDestAccount(e.target.value)}
                    className='w-2/3 border p-1 border-gray-300 rounded-md focus:outline-none'>
                    <option value=''>Choose your destination account</option>
                    <option value='BCA Ocommerce (277 050 5006)'>
                      BCA Ocommerce (277 050 5006)
                    </option>
                    <option value='BCA Ocommerce (277 269 8080)'>
                      BCA Ocommerce (277 269 8080)
                    </option>
                    <option value='BRI Ocommerce (0520 01 000285 30 1)'>
                      BRI Ocommerce (0520 01 000285 30 1)
                    </option>
                    <option value='BCA Guntur (647 051 4289)'>
                      BCA Guntur (647 051 4289)
                    </option>
                    <option value='Doku'>
                      Doku
                    </option>
                    <option value='Midtrans'>
                      Midtrans
                    </option>
                    {/* <option value='WhatsApp'>
                      WhatsApp
                    </option> */}
                  </select>
                </div>
                
                {/* {(status !== 'installment' && minInstallment < 100) &&
                  <div className='flex'>
                    <div className='w-1/3'>Choose Percentage :</div>
                    <select 
                      onChange={(e) => set_Cicilan(e.target.value)}
                      className='w-2/3 border p-1 border-gray-300 rounded-md focus:outline-none'
                    >
                      <option value="0">Select cicilan</option>
                      {persentaseCicilanPertama.filter((i) => i >= minInstallment).map((cicilan) => (
                        <option value={cicilan}>{cicilan}%</option>
                      ))}
                    </select>
                  </div>
                } */}
              </div>
              {destAccount !== 'WhatsApp' &&
                <div>
                  <div
                    className={`flex flex-col items-center justify-center rounded-md h-40 w-full border mt-3 p-3 ${
                      image
                        ? 'ring ring-blue-300 border-blue-500'
                        : 'border-gray-300'
                    }`}>
                    {image && (
                      <img
                        src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/${image}`}
                        alt=''
                        className='h-28 object-contain'
                      />
                    )}
                    {/* <p className='italic text-gray-500'>Image Preview</p> */}
                    <label
                    htmlFor='icon-button-file'
                    className={`${image ? "text-sm text-gray-400 hover:text-gray-600 hover:font-bold cursor-pointer" : "text-sm p-2 text-white bg-blue-500 rounded-md"} flex items-center gap-2`}>
                      <p>{image ? "Change Image" : "Upload Image"}</p>
                      <Input
                        accept='image/*'
                        id='icon-button-file'
                        onChange={(e) => submitImage(e)}
                        type='file'
                      />
                      {image ? null :
                        <CameraAltIcon fontSize='small' />
                      }
                    </label>
                  </div>
                  {image && <em className='text-xs text-gray-500'>{image} uploaded</em>}
                </div>
              }

              {!(~~cicilan) && !rincianCicilan?.payment_1 ? null :
                <div className="border border-gray-300 rounded-md p-2 my-3">
                  <div className="font-bold">Rincian Cicilan</div>
                  <div className="cicilan-1 text-sm flex justify-between font-semibold mt-2">
                    <div className="">Payment 1</div>
                    <div className="">Rp. {(~~rincianCicilan?.payment_1 ? ~~rincianCicilan?.payment_1 : cicilanPertama).toLocaleString('id-ID')}</div>
                  </div>
                  <div className="cicilan-2 text-sm flex justify-between text-gray-400 font-semibold mt-2">
                    <div className="">Total Payment 2</div>
                    <div className="">Rp. {(~~rincianCicilan?.total_payment_2 ? ~~rincianCicilan?.total_payment_2 : cicilanKedua).toLocaleString('id-ID')}</div>
                  </div>
                  {rincianCicilan?.charge ?
                    <div className="cicilan-2 text-sm flex justify-between text-gray-400 font-semibold mt-2">
                      <div className="">Charge</div>
                      <div className="">Rp. {(~~rincianCicilan?.charge).toLocaleString('id-ID')}</div>
                    </div>
                  : null }
                </div>
              }

              {status === 'installment' &&
                <>
                  {rincianCicilan?.rincian_payment_2?.length &&
                    <>
                      <div className="border-b border-gray-300 border-dashed my-4"></div>
                      <div className="border border-gray-300 rounded-md p-2 my-3">
                        <div className="font-bold">Payment 2</div>
                        {rincianCicilan?.rincian_payment_2?.map((pay) =>
                          <div key={pay.id_so} className="cicilan-1 flex justify-between items-center mt-2">
                            <div className="">
                              <div className="text-gray-300 text-sm">Produk {pay.id_so}</div>
                              <div className="font-semibold">Rp. {(~~pay.cicilan).toLocaleString('id-ID')}</div>
                            </div>
                            <div className={`${(destAccount.trim().length !== 0 && destAccount.trim() === "WhatsApp") || 
                                        (destAccount.trim().length !== 0 && destAccount.trim() !== "WhatsApp" && image.trim() !== '' && pay.id_so === id_so) ? "text-white bg-blue-400 hover:bg-blue-700 cursor-pointer" : "text-gray-500 bg-gray-200"
                                      } text-xs py-3 px-4 rounded-md`}
                            onClick={() => (destAccount.trim().length !== 0 && destAccount.trim() !== "WhatsApp" && image.trim() !== '' && pay.id_so === id_so) && manualPayment(pay.id_so)}>Submit Payment</div>
                          </div>
                        )}
                      </div>
                    </>
                  }
                </>
              }

              {status !== 'installment' &&
                <div className='flex justify-between items-center'>
                  {isLoading ? (
                    <div className='flex items-center justify-center'>
                      <CircularProgress className='text-xs' />
                      <div className='text-center items-center justify-center'>
                        Loading Submit Payment
                      </div>
                    </div>
                  ) : (
                    <button
                      className={`${(destAccount.trim().length !== 0 && destAccount.trim() === "WhatsApp") || 
                                    (destAccount.trim().length !== 0 && destAccount.trim() !== "WhatsApp" && image.trim() !== '') ? "bg-blue-400 hover:bg-blue-700" : "bg-gray-400"
                                  } text-white text-sm p-2 rounded-md ml-auto`}
                      onClick={() => (destAccount.trim().length !== 0 && destAccount.trim() === "WhatsApp") || 
                                        (destAccount.trim().length !== 0 && destAccount.trim() !== "WhatsApp" && image.trim() !== '') ? 
                                        manualPayment() : null
                              }>
                      Submit Payment
                    </button>
                  )}
                </div>
              }
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ManualPayment;