import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { approvalPayment, get_detail_approval } from "service/api";
import swal from "sweetalert";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getUser } from "helpers/parseJWT";
import Lightbox from "react-image-lightbox";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import VerifiedIcon from '@mui/icons-material/Verified';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  maxHeight: "95vh",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const submitApproval = async (payload) => {
  const response = await approvalPayment(payload);
  if (response.status === 200) {
    return {
      success: true,
      message: "",
    };
  } else {
    return {
      success: false,
      message: response.message,
    };
  }
};

const ModalApproval = ({ type, id_group, id_so, id, image, setUpdate }) => {
  const [detailApproval, setDetailApproval] = useState([]);
  const [open, setOpen] = useState(false);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedIdSo, setSelectedIdSo] = useState(null);

  const handleOpen = async () => {
    const params = `id_group=${id_group}&id_so=${id_so}`
    const response = await get_detail_approval(params)
    if (response.status === 200) {
      setDetailApproval(response.data)
      setOpen(true);
    } else {
      swal("Failed", "Sorry, something wrong happened. Please try again.", "error");
    }
  }
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);

  const handleApproval = (type, id, id_so) => {
    swal({
      title: "Are you sure?",
      text: "Once approve payment, you will not be able to revert this change!",
      icon: "warning",
      buttons: true,
    }).then(async (willApprove) => {
      if (willApprove) {
        setLoading(true);
        const payload = {
          type,
          id,
          id_group,
          id_so,
          approve: true,
          reason: "",
        };

        const response = await submitApproval(JSON.stringify(payload));
        if (response.success) {
          swal("Success", "Payment Approved", "success");
          handleClose();
          setUpdate((prev) => !prev);
        } else {
          swal("Oops", response.message, "error");
        }
        setLoading(false);
      }
    });
  };
  
  const totalInstallmentAmount = detailApproval.reduce((total, item) => {
    return total + item.installment_amount;
  }, 0);

  return (
    <div>
      <button
        className={`w-full text-center text-sm  py-2 text-white  rounded-md cursor-pointer bg-blue-400`}
        onClick={handleOpen}>
        Approval
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <div className='flex justify-between items-center -mt-5'>
            <div className=" flex gap-2 items-center">
              <div className="font-bold">Approval</div>
              {detailApproval[0]?.is_installment && (
                <div className="text-white px-3 py-1 rounded-full" style={{ backgroundColor: "#095681" }}> <VerifiedIcon className="mr-1" />Instalment</div>
              )}
            </div>
            <IconButton onClick={handleClose} style={{ textAlign: "right" }}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className=" py-3 flex flex-col items-start">
            {detailApproval[0]?.is_installment && (
            <p>Cicilan ke-{detailApproval[0]?.installment_number}</p>
            )}
          </div>
          <div className="flex flex-col gap-2">
            {detailApproval?.map((order) => (
              <div key={order.id_so} className="border border-gray-400 rounded-md hover:bg-gray-100 cursor-pointer overflow-hidden"
                onClick={() => selectedIdSo === order.id_so ? setSelectedIdSo(null) : setSelectedIdSo(order.id_so)}
              >
                <div className="w-full bg-gray-200 flex justify-between items-center px-3 py-1">
                  {order.id_so}
                  <ChevronLeft className={`${selectedIdSo === order.id_so ? "rotate-90" : "-rotate-90"}`} />
                </div>
                <div className={`${selectedIdSo === order.id_so ? 'overflow-auto' : 'overflow-hidden'} transition-all duration-300 ease overflow-auto`}
                  style={{
                    maxHeight: selectedIdSo === order.id_so ? "70vh" : "0px"
                  }}
                >
                  <div className="text-lg font-semibold my-2">
                    Proof of payment
                  </div>
                  <img
                    src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/${order.proof_image}`}
                    alt='bukti pembayaran'
                    className='w-20 h-20 mx-auto object-contain bg-gray-100 cursor-pointer rounded-md overflow-hidden'
                    onClick={() => setOpenLightbox(order.proof_image)}
                  />

                  {openLightbox === order.proof_image && (
                    <Lightbox
                      mainSrc={`${process.env.REACT_APP_URL_FOTO_STORAGE}/${order.proof_image}`}
                      onCloseRequest={() => setOpenLightbox(false)}
                    />
                  )}

                  <div className="max-w-[600px] mx-auto mt-5 mb-5">
                    <table className="w-full">
                      <thead className="bg-gray-50">
                        <tr>
                          <td className="p-1">Jenis</td>
                          <td className="p-1">Qty</td>
                          <td className="p-1">Price</td>
                          <td className="p-1">Total</td>
                        </tr>
                      </thead>
                      <tbody>

                        {!order?.data ? null : order?.data?.map((item, index) => (
                          <tr key={item.product} className="bg-white border-b">
                            <td className="p-1 line-clamp-2 text-left">{item.product}</td>
                            <td className="p-1 w-20">{item.qty}</td>
                            <td className="p-1 w-20">{item.price.toLocaleString("id-ID")}</td>
                            <td className="p-1 whitespace-nowrap text-right">
                              Rp. {(item.qty * item.price).toLocaleString("id-ID")}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={3} className="pt-2 text-right font-semibold pr-3">Grand Total</td>
                          <td className="text-right whitespace-nowrap pt-2 px-1">
                            Rp. {order?.data.reduce((pV, cV) => pV + (cV.price * cV.qty), 0).toLocaleString("id-ID")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* ///////// TEMPORARY COMMENTED ///////// */}
          {/* <Typography id='modal-modal-title' variant='h6' component='h2'>
            Proof of payment
          </Typography>
          <img
            src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/${image}`}
            alt='bukti pembayaran'
            className='max-w-sm h-[14rem] mx-auto object-contain cursor-pointer'
            onClick={() => setOpenLightbox(image)}
          />
          
          {openLightbox === image && (
            <Lightbox
              mainSrc={`${process.env.REACT_APP_URL_FOTO_STORAGE}/${image}`}
              onCloseRequest={() => setOpenLightbox(false)}
            />
          )}

          <div className="max-w-[600px] mx-auto mt-5 mb-5">
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  <td className="p-1">Jenis</td>
                  <td className="p-1">Qty</td>
                  <td className="p-1">Price</td>
                  <td className="p-1">Total</td>
                </tr>
              </thead>
              <tbody>
                
              {!detailApproval ? null : detailApproval?.map((item, index) => (
                  <tr key={item.product} className="bg-white border-b">
                    <td className="p-1 line-clamp-2 text-left">{item.product}</td>
                    <td className="p-1 w-20">{item.qty}</td>
                    <td className="p-1 w-20">{item.price.toLocaleString("id-ID")}</td>
                    <td className="p-1 whitespace-nowrap text-right">
                      Rp. {(item.qty * item.price).toLocaleString("id-ID")}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={3} className="pt-2 text-right font-semibold pr-3">Grand Total</td>
                  <td className="text-right whitespace-nowrap pt-2 px-1">
                  Rp. {detailApproval.reduce((pV, cV) => pV + (cV.price * cV.qty),0).toLocaleString("id-ID")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
          {detailApproval[0]?.is_installment && (
            <div className="flex justify-start mt-4">
              <p><b>Total Instalment :</b> Rp {totalInstallmentAmount.toLocaleString('ID-id')}</p>
            </div>
          )}
          {getUser().roles?.includes('admin') &&
            <>
              <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                Once approve payment, you will not be able to revert this change!
              </Typography>
              <div className='mt-3 flex space-x-3 justify-center'>
                <RejectModal
                  id={id}
                  id_so={id_so}
                  type={type}
                  setUpdate={setUpdate}
                  close={handleClose}
                />
                <button
                  onClick={() => getUser().roles?.includes('admin') && handleApproval(type, id, id_so)}
                  disabled={loading}
                  className={`${loading ? "bg-gray-500" : "bg-green-500"
                    } rounded-md text-white px-5 py-1`}>
                  Approve
                </button>
              </div>
            </>
          }
        </Box>
      </Modal>
    </div>
  );
};

export default ModalApproval;

const RejectModal = ({ type, id, id_so, setUpdate, close }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  const handleReject = async (e, type, id, id_so) => {
    e.preventDefault();
    setLoading(true);

    if (reason.trim().length === 0) {
      swal("Oops", "Please input the reason for rejected payment", "warning");
      setLoading(false);
      return false;
    }

    const payload = {
      type,
      id,
      id_so,
      reason,
      approve: false,
    };
    const response = await submitApproval(JSON.stringify(payload));
    if (response.success) {
      swal("Success", "Payment Rejected", "success");
      setUpdate((prev) => !prev);
      close();
      handleClose();
    } else {
      swal("Oops", response.message, "error");
    }
    setLoading(false);
  };

  return (
    <div>
      <button
        onClick={handleOpen}
        className='bg-red-500 rounded-md text-white px-5 py-1'>
        Reject
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <form onSubmit={(e) => handleReject(e, type, id, id_so)}>
            <p className='text-left mb-1'>Please Input Reason :</p>
            <textarea
              onChange={(e) => setReason(e.target.value)}
              className='w-full border border-gray-500 rounded-md p-2'
              rows={4}
              placeholder='please input reason'></textarea>
            <div className='flex justify-end space-x-2'>
              <button
                onClick={handleClose}
                className='bg-red-500 text-white px-4 py-1 rounded-md'>
                Cancel
              </button>
              <button
                type='submit'
                disabled={loading}
                className={`${loading ? "bg-gray-500" : "bg-blue-500"
                  } text-white px-4 py-1 rounded-md`}>
                Submit
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};