import React from "react";
import { Redirect } from "react-router-dom";

const Purchasing = () => {
  return (
    <>
      <Redirect to="purchasing/new-order?toko=all" />
    </>
  );
};

export default Purchasing;
