// import { getUser } from 'helpers/parseJWT';

import { useTranslation } from "react-i18next";

export const SubRoutesLogisticChina = () => {
    const { t } = useTranslation()
    return [
    { name: t('Received Items'), pathname: '/logistic-china/received-items?toko=all' },
    { name: t('Processing'), pathname: '/logistic-china/repacking' },
    { name: t('Send to IDN'), pathname: '/logistic-china/send-to-idn-v2' },
    { name: t('Container'), pathname: '/logistic-china/container' },
    { name: t('OTW IDN'), pathname: '/logistic-china/otw-idn' },
    { name: t('Arrived IDN'), pathname: '/logistic-china/arrived-idn' },
    { name: t('Abnormal'), pathname: '/logistic-china/abnormal' },
]};