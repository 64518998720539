import { useCallback, useState, memo, useRef, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import {
  cancelVolumeData,
  cancelVolumeDataAll,
  getDetailRequest,
  get_history_sales_request,
  get_list_pic,
  inputSalesProduct,
  inputsalesVolumePIC,
  submit_edit_sales_request,
} from 'service/api';
import { getUser } from 'helpers/parseJWT';
import RestoreIcon from '@mui/icons-material/Restore';
import CloseIcon from '@mui/icons-material/Close';
import swal from 'sweetalert';
import NumberFormat from 'react-number-format';
// import pic from 'helpers/pic';
import moment from 'moment';
import DetailRequestChild from './DetailRequestChild';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth : "95dvw",
  maxHeight : "95dvh",
  // width: 1200,
  // maxHeight: 800,
  overFlow: 'auto',
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};

export default function DetailRequestModal({
  id_request,
  isLoading,
  setIsLoading,
  link,
  setUpdate,
}) {
  const userSession = getUser()
  // console.log('getUser()', getUser().division[0])
  // console.log('getUser role', getUser().role)

  const [open, setOpen] = useState(false);

  const { t } = useTranslation()

  const handleOpen = () => {
    getDetailData(id_request);
    set_OpenSelectPIC()
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false);
    resetCheckBox()
  };
  const [payloadSalesProduct, setPayloadSalesProduct] = useState({
    id: id_request,
    email: '',
  });
  const [dataOriginal, setDataOriginal] = useState([]);
  const [simulationInput, setSimulationInput] = useState([]);
  const [selectedPic, setSelectedPic] = useState('');
  const [rollbackData, setRollbackData] = useState(null)
  const [rollbackAllData, setRollbackAllData] = useState(null)
  const [isLoadingRollback, setIsLoadingRollback] = useState(false)
  const [requiredBuyPerBox, setRequiredBuyPerBox] = useState(false)
  const [completedExist, setCompletedExist] = useState(false)
  // const [isDirty, setIsDirty] = useState(false)
  const [histories, setHistories] = useState([])
  const [showHistory, setShowHistory] = useState(false)
  const isDirty = useRef(false)
  const [updateAll, setUpdateAll] = useState({
    isChecked: false,
  });
  const [pic, setPic] = useState([]);

  const rollBack = (id_request, id_product) => {
    setRollbackData({
      id_request,
      id_product
    })
  };

  const showModalRollbackAll = (id_request) => {
    setRollbackAllData(id_request)
  };


  const handleChecked = (e, id) => {
    const { name, checked } = e.target;
    if (id !== undefined) {
      const values = [...simulationInput];
      values[id][name] = checked;
      setSimulationInput(values);
      const checkAll = values.filter((data) => !data[name] || data[name] === false);
      if (checkAll.length > 0) {
        setUpdateAll((prev) => {
          return { ...prev, [name]: false };
        });
      } else {
        setUpdateAll((prev) => {
          return { ...prev, [name]: true };
        });
      }
    } else {
      setUpdateAll((prev) => {
        return { ...prev, [name]: checked };
      });
      setSimulationInput(
        simulationInput.map((data) => {
          return { ...data, [name]: checked };
        })
      );
    }
    validateMinQty(requiredBuyPerBox)
  };


  // const handleCancelVolume = async (id_request, id_product, reason) => {
  //   setIsLoading(true);
  //   const body = JSON.stringify({ id_request, id_product, reason });
  //   const data = await cancelVolumeData(body);
  //   if (data?.status === 200) {
  //     swal('Success', 'Rollback data success', 'success');
  //     getDetailData(id_request);
  //     setRollbackData(null)
  //   }
  //   if (data?.status === 403) {
  //     swal('Oops', data?.message, 'error');
  //   }
  //   setIsLoading(false);
  // };
  const resetCheckBox = () => {
    setUpdateAll({
      isChecked: false,
    })
    setSimulationInput(item => {
      return item.map(v => ({
        ...v,
        isChecked: false
      }))
    })
  }

  const handleCancelVolume = async (id_request, id_products, reason) => {
    if (id_products.length == 0) {
      swal('Oops', 'Please select somne row for rollback!', 'error');
      return false
    }
    if (reason == "") {
      swal('Oops', 'Please insert reason!', 'error');
      return false
    }
    setIsLoading(true);
    const body = JSON.stringify({ id_request, id_products, reason });
    const data = await cancelVolumeData(body);
    if (data?.status === 200) {
      swal('Success', 'Rollback data success', 'success');
      getDetailData(id_request);

      resetCheckBox()
    }
    if (data?.status === 403) {
      swal('Oops', data?.message, 'error');
    }
    setRollbackData(null)
    setIsLoading(false);
  };

  const handleRollbackAll = async (id_request, reason) => {
    if (reason == "") {
      swal('Oops', 'Please insert reason!', 'error');
      return false
    }
    const body = { id_request, reason };
    setIsLoading(true);

    const response = await cancelVolumeDataAll(JSON.stringify(body));
    if (response?.status === 201) {
      swal('Success', 'Rollback All Data Success', 'success');
      setRollbackAllData(null)
      getDetailData(id_request);
      setUpdate((prev) => !prev);
      resetCheckBox()
    } else {
      swal('Oops', response?.message, 'error');
    }
    setIsLoading(false);
  };

  const ref = useRef();
  const onChange = () => {
    
  }
  // const onChange = () => toast.warning(`${requiredBuyPerBox ? 'Customer is required to buy per box!' : 'Quantity must correspond to the multiple value!'}`, { className: "bg-red-500" })

  function debounce(callback, time) {
    let interval;
    return () => {
      clearTimeout(interval)
      interval = setTimeout(() => {
        interval = null
        callback(arguments)
      }, time)
    }
  }

  useEffect(() => {
    ref.current = onChange
  }, [onChange]);

  const doCallbackWithDebounce = useMemo(() => {
    const callback = () => ref.current()
    return debounce(callback, 500);
  }, []);

  const handleInput = (e, id) => {
    const { name, value } = e.target;
    const newSimulationInput = [...simulationInput];

    if (id !== undefined) {
      if (name === 'min_qty') {
        newSimulationInput[id][name] = ~~value
        if (requiredBuyPerBox) {
          if ((value < newSimulationInput[id].qty)) { // simulationInput[id].qty is considered as qty/box
            newSimulationInput[id].invalid_min_qty = true
            doCallbackWithDebounce()
            // toast.warning(`Customer is required to buy per box!`, {className: "bg-red-500"})
          } else {
            if (value % newSimulationInput[id].qty === 0) {
              newSimulationInput[id].invalid_min_qty = false
            } else {
              newSimulationInput[id].invalid_min_qty = true
              doCallbackWithDebounce()
              // toast.warning(`Quantity must be multiple of ${newSimulationInput[id].qty}!`, {className: "bg-red-500"})
            }
          }
        } else {
          if (newSimulationInput[id].kuantiti_kelipatan > 1) {
            if (value % newSimulationInput[id].kuantiti_kelipatan === 0) {
              newSimulationInput[id].invalid_min_qty = false
            } else {
              newSimulationInput[id].invalid_min_qty = true
              doCallbackWithDebounce()
            }
          } else {
            newSimulationInput[id].invalid_min_qty = false
          }
        }
      } else {
        newSimulationInput[id][name] = name !== 'value' ? ~~value : parseFloat(value);
        if (newSimulationInput[id].kuantiti_kelipatan > 1) {
          if (newSimulationInput[id].min_qty % newSimulationInput[id].kuantiti_kelipatan === 0) {
            newSimulationInput[id].invalid_min_qty = false
          } else {
            newSimulationInput[id].invalid_min_qty = true
            doCallbackWithDebounce()
          }
        } else {
          newSimulationInput[id].invalid_min_qty = false
        }
      }
      setSimulationInput(newSimulationInput)
    } else {
      newSimulationInput.forEach((item, index) => {
        if (name === 'min_qty' && (value !== '' && value !== 0)) {
          if (requiredBuyPerBox) {
            item[name] = Math.ceil(value / item.qty) * item.qty
            if (value < item.qty || (value > item.qty && value % item.qty !== 0)) {
              doCallbackWithDebounce()
            }
          } else {
            if (item.kuantiti_kelipatan > 1) {
              if (value % item.kuantiti_kelipatan === 0) {
                item[name] = ~~value
              } else {
                item[name] = Math.ceil(value / item.kuantiti_kelipatan) * item.kuantiti_kelipatan
                if (index === 0) {
                  doCallbackWithDebounce()
                }
              }
            } else {
              item[name] = ~~value
            }
          }
          delete item.invalid_min_qty
        } else {
          item[name] = name !== 'value' ? ~~value : item.beginPrice ? parseFloat(value) : item[name];
          if (item.kuantiti_kelipatan > 1) {
            if (item.min_qty % item.kuantiti_kelipatan === 0) {
              item.invalid_min_qty = false
            } else {
              item.invalid_min_qty = true
              if (index === 0) {
                doCallbackWithDebounce()
              }
            }
          } else {
            item.invalid_min_qty = false
          }
        }
      })
      setSimulationInput(newSimulationInput)
    }
    isDirty.current = true
  }

  const getDetailData = async (id) => {
    setIsLoadingRollback(true);
    const data = await getDetailRequest(id);
    if (data?.status === 200) {
      const completed_exist = data.data?.filter((item) => item.status === "Completed")
      if (completed_exist.length) {
        setCompletedExist(true)
      } else {
        setCompletedExist(false)
      }
      setDataOriginal(data.data)
      setSimulationInput(
        data.data?.map((detail) => {
          return { ...detail, kuantiti_kelipatan : detail.kuantiti_kelipatan || 1 ,value: detail.price };
        })
      );
      setRequiredBuyPerBox(data.box)
    }
    setIsLoadingRollback(false);
  };

  const SalesInputProduct = async () => {
    const { id, email } = payloadSalesProduct;
    const body = JSON.stringify({
      id,
      email,
    });
    const data = await inputSalesProduct(body);
    if (data?.status === 200) {
      swal('success', 'Input Product From Sales', 'success');
      setPayloadSalesProduct({
        ...payloadSalesProduct,
        email: '',
      });
    } else {
      swal('Failed', `Error Message : ${data?.message}`, 'error');
    }
  };

  const handleSubmitPIC = async () => {
    const body = JSON.stringify({ pic: selectedPic, link: link });

    if (selectedPic === '') {
      swal('warning', 'Selected Volume PIC Cannot be empty', 'warning');
      return;
    }

    const response = await inputsalesVolumePIC(body);
    if (response?.status === 201) {
      setOpen((prev) => !prev);
      swal('Success', 'PIC Submitted successfully', 'success');
      setUpdate((prev) => !prev);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    } else {
      swal('Failed', `Error : ${response?.message}`, 'error');
    }
  };

  const handleRollback = () => {
    const id_products = simulationInput.filter(v => v.isChecked).map(v => v.id)
    if (id_products.length == simulationInput.length) {
      showModalRollbackAll(id_request)
      return
    } else {
      setRollbackData({
        id_request,
        id_product: id_products
      })
    }
  }

  const handleSubmitChanges = async () => {
    const selectedProducts = simulationInput.filter((item) => item.isChecked === true).map((prod) => {
      return {
        id_product: prod.id,
        price: prod.value,
        length: prod.length,
        width: prod.width,
        height: prod.height,
        min_purchase: prod.min_qty,
        kuantiti_kelipatan: prod.kuantiti_kelipatan,
      }
    })
    const payload = {
      id_request: id_request,
      box: requiredBuyPerBox,
      product: selectedProducts
    }

    const response = await submit_edit_sales_request(JSON.stringify(payload))
    if (response?.status === 200) {
      setOpen((prev) => !prev);
      swal('Success', 'Changes Submitted successfully', 'success');
      setUpdate((prev) => !prev);
    } else {
      swal('Failed', `Error : ${response?.message}`, 'error');
    }
  }

  const show_History = async () => {
    const response = await get_history_sales_request(id_request)
    if (response?.status === 200) {
      setHistories(response.data)
      setShowHistory(true)
    } else {
      swal('Failed', `Error : ${response?.message}`, 'error');
    }
  }

  const set_OpenSelectPIC = async () => {
    const response = await get_list_pic();
    if (response?.status === 200) {
      setPic(response.data);
    } else {
      swal('Failed', `Error : ${response?.message}`, 'error');
    }
  }

  const set_RequiredBuyPerBox = (checked) => {
    setRequiredBuyPerBox(checked)
    if (checked) {
      setSimulationInput((prev) => {
        const final = prev.map((data, index) => {
          if (data.min_qty < data.qty) {
            data.min_qty = data.qty
          }
          return data
        })

        return final
      })
    } else {
      setSimulationInput((prev) => {
        const final = prev.map((data, index) => {
          data.min_qty = dataOriginal[index].min_qty
          return data
        })

        return final
      })
    }
    validateMinQty(checked)
  }

  const validateMinQty = (checked) => {
    setSimulationInput((prev) => {
      const newSimulationInput = JSON.parse(JSON.stringify(prev))
      newSimulationInput.forEach((item, index) => {
        if (checked) {
          
          if (item.min_qty % item.qty === 0) {
            item.invalid_min_qty = false
          } else {
            item.invalid_min_qty = true
            if (index === 0) {
              doCallbackWithDebounce()
            }
          }
        } else {
          if (item.min_qty % item.kuantiti_kelipatan === 0) {
            item.invalid_min_qty = false
          } else {
            item.invalid_min_qty = true
            if (index === 0) {
              doCallbackWithDebounce()
            }
          }
        }
      })
      return newSimulationInput
    })
  }

  if (!simulationInput) {
    return null
  }
  return (
    <>
      <div
        className='py-2 px-5 border text-blue-500  text-sm border-blue-500 rounded-md text-center cursor-pointer'
        onClick={handleOpen}>
        {t("Details")}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style} className='flex flex-col'>
          <div className='flex justify-between items-center -mt-5'>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Detail Request
            </Typography>
            <IconButton onClick={handleClose} style={{ textAlign: 'right' }}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className='text-sm font-semibold my-2'>
            Link Product :{' '}
            <Tooltip title={link}>
              <a
                href={link}
                target='_blank'
                className='font-normal hover:text-blue-500'
                rel='noreferrer'>
                {' '}
                {link.slice(0, 100)}
                {link.length > 100 && ' ....'}
              </a>
            </Tooltip>
          </div>
          {isLoading || isLoadingRollback ? (
            <div className='flex items-center justify-center w-full h-full p-4 rounded-md'>
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className='flex-1 flex items-center gap-5 w-full mb-2'>
                {/* <Typography id='modal-modal-title' variant='h6' component='h2'>
                  Draft Order
                </Typography>
                <input
                  name='email'
                  className='border-gray-400 focus:outline-blue rounded-md border p-2 w-64'
                  placeholder='Masukan Email User'
                  value={payloadSalesProduct.email}
                  onChange={(e) => {
                    setPayloadSalesProduct({
                      ...payloadSalesProduct,
                      email: e.target.value,
                    });
                  }}
                />
                <button
                  onClick={SalesInputProduct}
                  className='py-2 px-5 bg-blue-500 rounded-md text-white'>
                  Submit
                </button> */}
                {
                  getUser().role !== 'sales' &&
                    getUser().division[0] !== 'asmen' &&
                    getUser().division[0] !== 'manager' &&
                    getUser().division[0] !== 'supervisor' ?
                    <>
                      <div className='flex items-center space-x-3'>
                        <p>Check Details PIC : </p>
                        <select
                          name='select'
                          value={selectedPic}
                          onChange={(e) => setSelectedPic(e.target.value)}
                          className='border border-gray-300 p-1 rounded-md focus-within:outline-blue capitalize'>
                          <option value='' disabled>
                            Select Volume PIC
                          </option>
                          {pic?.map((p) => (
                            <option key={p.user} value={p.user}>{p.user}</option>
                          ))}
                        </select>
                        <button
                          onClick={() => 
                            getUser().role !== 'sales' &&
                            getUser().division[0] !== 'asmen' &&
                            getUser().division[0] !== 'manager' &&
                            getUser().division[0] !== 'supervisor' ? handleSubmitPIC() : undefined}
                          className={` ${
                            getUser().role !== 'sales' &&
                            getUser().division[0] !== 'asmen' &&
                            getUser().division[0] !== 'manager' &&
                            getUser().division[0] !== 'supervisor' ? " border-blue-500 hover:bg-blue-500 hover:text-white" : `bg-gray-200`} px-3 py-1 border-2  text-gray-700 rounded-md`}>
                          Submit PIC
                        </button>
                      </div>
                    </> : ""}


                <div className="history py-1 px-5 bg-blue-500 rounded-md text-white ml-auto cursor-pointer" onClick={show_History}>
                  History
                </div>
              </div>

              {completedExist &&
                <div className="required-buy-per-box flex items-center gap-2 text-sm mb-3">
                  <input
                    type='checkbox'
                    name='requiredBuyPerBox'
                    style={{ width: 20, height: 20 }}
                    checked={requiredBuyPerBox}
                    onChange={(e) => set_RequiredBuyPerBox(e.target.checked)}
                  /> {t("Customer is required to buy per box")}
                </div>
              }
              <TableContainer
                component={Paper}
                className='table-scroll variant-scroll flex-1'
                sx={{ maxHeight: "100%" }}>
                <Table
                  // stickyHeader
                  size='small'
                  sx={{ minWidth: 500 }}
                  aria-label='custom pagination table'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>
                        <input
                          type='checkbox'
                          name='isChecked'
                          style={{ width: 20, height: 20 }}
                          checked={updateAll.isChecked}
                          onChange={handleChecked}
                        />
                      </TableCell>
                      <TableCell align='center'>Variant</TableCell>
                      <TableCell align='center'>Qty/Box</TableCell>
                      {completedExist &&
                        <>
                          <TableCell align='center'>
                            Multiple
                            {
                              getUser().role !== 'sales' &&
                                getUser().division[0] !== 'asmen' &&
                                getUser().division[0] !== 'manager' &&
                                getUser().division[0] !== 'supervisor' ?
                                <>
                                  <input
                                    type="number"
                                    name="kuantiti_kelipatan"
                                    className="border text-xs text-center border-gray-300 focus:outline-blue w-20 p-1 rounded-md"
                                    onChange={(e) => handleInput(e)}
                                    onKeyPress={(e) => {
                                      if (!/[0-9]|\./.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    min="0"
                                  />
                                </> : ``}
                          </TableCell>
                          <TableCell align='center'>
                            Min Qty
                            {
                              getUser().role !== 'sales' &&
                                getUser().division[0] !== 'asmen' &&
                                getUser().division[0] !== 'manager' &&
                                getUser().division[0] !== 'supervisor' ?
                                <>
                                  <input
                                    type="number"
                                    name="min_qty"
                                    className="border text-xs text-center border-gray-300 focus:outline-blue w-20 p-1 rounded-md"
                                    onChange={(e) => handleInput(e)}
                                    onKeyPress={(e) => {
                                      if (!/[0-9]|\./.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    min="0"
                                  />
                                </> : ``}

                          </TableCell>
                        </>
                      }
                      <TableCell align='center'>Weight(Gram)</TableCell>
                      <TableCell align='center'>Volume(CM3)</TableCell>
                      <TableCell align='center'>
                        Length(CM)
                        {
                          getUser().role !== 'sales' &&
                            getUser().division[0] !== 'asmen' &&
                            getUser().division[0] !== 'manager' &&
                            getUser().division[0] !== 'supervisor' ?
                            <>
                              <input
                                type="number"
                                name="length"
                                className="border text-xs text-center border-gray-300 focus:outline-blue w-20 p-1 rounded-md"
                                onChange={(e) => handleInput(e)}
                              />
                            </> : ``}

                      </TableCell>
                      <TableCell align='center'>
                        Width(CM)
                        {
                          getUser().role !== 'sales' &&
                            getUser().division[0] !== 'asmen' &&
                            getUser().division[0] !== 'manager' &&
                            getUser().division[0] !== 'supervisor' ?
                            <>
                              <input
                                type="number"
                                name="width"
                                className="border text-xs text-center border-gray-300 focus:outline-blue w-20 p-1 rounded-md"
                                onChange={(e) => handleInput(e)}
                              /> 
                            </> : ``}

                      </TableCell>
                      <TableCell align='center'>
                        Height(CM)
                        {
                          getUser().role !== 'sales' &&
                            getUser().division[0] !== 'asmen' &&
                            getUser().division[0] !== 'manager' &&
                            getUser().division[0] !== 'supervisor' ?
                            <>
                              <input
                                type="number"
                                name="height"
                                className="border text-xs text-center border-gray-300 focus:outline-blue w-20 p-1 rounded-md"
                                onChange={(e) => handleInput(e)}
                              />
                            </> : ``}

                      </TableCell>
                      <TableCell align='center'>HScode</TableCell>
                      <TableCell align='center' className='whitespace-nowrap'>Is Lartas</TableCell>
                      <TableCell align='center'>Category</TableCell>
                      <TableCell align='center'>
                        {' '}
                        <div className='space-y-px'>
                          <p>status</p>
                          {/* <div
                            className='p-1 space-x-1 bg-blue-500 w-24 text-white flex items-center rounded-md text-xs justify-center font-semibold cursor-pointer mt-1'
                            onClick={e => showModalRollbackAll(id_request)}>
                            <RestoreIcon style={{ fontSize: 20 }} />
                            <p className='text-center'>Rollback</p>
                          </div> */}
                        </div>
                      </TableCell>
                      <TableCell align='center'>Type</TableCell>
                      <TableCell align='center'>Price/box</TableCell>
                      <TableCell align='center'>Price/pcs</TableCell>
                      <TableCell align='center'>Silver</TableCell>
                      <TableCell align='center'>Gold</TableCell>
                      <TableCell align='center'>Diamond</TableCell>
                      {completedExist &&
                        <TableCell align='center'>
                          Price/pcs(RMB)
                          {
                            getUser().role !== 'sales' &&
                              getUser().division[0] !== 'asmen' &&
                              getUser().division[0] !== 'manager' &&
                              getUser().division[0] !== 'supervisor' ?
                              <>
                                <input
                                  type="number"
                                  name="value"
                                  className="border text-xs border-gray-300 focus:outline-blue w-20 p-1 rounded-sm"
                                  onChange={(e) => handleInput(e)}
                                />
                              </> : ``}
                        </TableCell>
                      }

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {simulationInput.map((row, id) => (
                      <DetailRequestChild
                        key={id}
                        row={row}
                        id={id}
                        simulationInput={simulationInput}
                        handleChecked={handleChecked}
                        handleInput={handleInput}
                        requiredBuyPerBox={requiredBuyPerBox}
                        completedExist={completedExist}
                      />
                    ))}
                    {simulationInput.length === 0 && (
                      <TableRow style={{ height: '30vh' }}>
                        {isLoading ? (
                          <TableCell colSpan={13} align='center'>
                            <CircularProgress />
                          </TableCell>
                        ) : (
                          <TableCell colSpan={13} align='center'>
                            No data available
                          </TableCell>
                        )}
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {
            getUser().role !== 'sales' &&
              getUser().division[0] !== 'asmen' &&
              getUser().division[0] !== 'manager' &&
              getUser().division[0] !== 'supervisor' ?
              <>
                <div className="flex justify-end items-center gap-3">
                  {!isDirty ||
                    (simulationInput.filter((item) => item.isChecked === true).length === 0 ||
                      (simulationInput.filter((item) => item.isChecked === true).filter((item) => item.kuantiti_kelipatan == 0 && item.min_qty == 0).length !== 0) ||
                      (simulationInput.filter((item) => item.isChecked === true).filter((item) => item.invalid_min_qty === true).length !== 0) ||
                      (!requiredBuyPerBox && (simulationInput.filter((item) => item.isChecked === true).filter((item) => item.min_qty < item.kuantiti_kelipatan).length !== 0))
                    ) ? null :
                    <div
                      className='px-3 py-2 bg-blue-500 text-white rounded-md text-xs font-semibold cursor-pointer mt-1 whitespace-nowrap'
                      onClick={handleSubmitChanges}>
                      Submit Changes
                    </div>
                  }
                  {
                    !isLoading && !isLoadingRollback && (
                      <button
                        disabled={!userSession.division.includes("updater")}
                        className={`${userSession.division.includes("updater") ? "bg-blue-500" : "bg-gray-300 cursor-not-allowed"} px-3 py-2 w-24 text-white flex items-center rounded-md text-xs justify-center font-semibold cursor-pointer mt-1`}
                        onClick={() => handleRollback()}>
                        <RestoreIcon style={{ fontSize: 20 }} />
                        <p>Rollback</p>
                      </button>
                    )
                  }
                </div>

                {rollbackAllData && (<RollbackAllConfirmationModal
                  open={!!rollbackAllData}
                  rollbackAllData={rollbackAllData}
                  handleClose={() => { setRollbackAllData(null) }}
                  handleRollbackAll={handleRollbackAll}
                />)}

                {rollbackData && (<RollbackConfirmationModal
                  open={!!rollbackData}
                  rollbackData={rollbackData}
                  handleClose={() => { setRollbackData(null) }}
                  handleCancelVolume={handleCancelVolume}
                />)}

                {showHistory && (<HistoryModal
                  open={!!showHistory}
                  data={histories}
                  handleClose={() => { setShowHistory(false) }}
                />)}
              </>
              : ""}
        </Box>
      </Modal>
    </>
  );
}

const styleRollbackConfirmModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
  zIndex: 20,
};
const styleHistoryModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 600,
  maxWidth: 1200,
  maxHeight: '80vh',
  overflow: "auto",
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
  zIndex: 20,
};

const RollbackAllConfirmationModal = ({ open, handleClose, rollbackAllData, handleRollbackAll }) => {
  const [selectReason, setSelectReason] = useState("")
  const [reason, setReason] = useState("")
  const handleSubmit = () => {
    if (reason.length > 100) {
      swal('Oops', "the reason must be less than 100 character", 'error');
      return
    }
    handleRollbackAll(rollbackAllData, reason)
  }
  return (

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box sx={styleRollbackConfirmModal} className='overflow-y-scroll variant-scroll'>
        <div className='flex justify-end -mt-5'>
          <IconButton onClick={handleClose} style={{ textAlign: 'right' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="p-6 text-center">
          <svg aria-hidden="true" className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>

          <h1 className='font-bold text-2xl'>Are you sure</h1>
          <p className='mb-3'>You will rollback All data</p>
          <p>Please input the reason</p>

          <select name="" id="" className='w-full rounded border px-2 py-1 mb-3' value={selectReason} onChange={e => {
            setSelectReason(e.target.value)
            if (e.target.value == "other") {
              setReason("")
            } else {
              setReason(e.target.value)
            }
          }}>
            <option value="" disabled>Select Option</option>
            {
              [
                "Product price is different",
                "Product volume is not correct",
                "Product weight is not correct",
                "Variant is not complete",
                "Product MOQ is not correct",
                "Product Cubic/Metre is not correct",
                "Product category is not correct",
                "other"
              ].map(v => (<option key={v} value={v}>{v}</option>))
            }
          </select>
          {
            selectReason == "other" && (
              <div className='mb-3'>
                <input value={reason} onChange={e => setReason(e.target.value)} type="text" className='w-full rounded border px-2 py-1' placeholder='Insert reason ...' />
                <p className='text-right'>{reason.length} / 100</p>
              </div>
            )
          }
          <button onClick={handleSubmit} data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
            Yes, I'm sure
          </button>
          <button onClick={handleClose} data-modal-hide="popup-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No, cancel</button>
        </div>
        <div className='text-center'>
        </div>
      </Box>
    </Modal>
  )
}

const RollbackConfirmationModal = ({ open, handleClose, rollbackData, handleCancelVolume }) => {
  const [selectReason, setSelectReason] = useState("")
  const [reason, setReason] = useState("")
  const handleSubmit = () => {
    if (reason.length > 100) {
      swal('Oops', "the reason must be less than 100 character", 'error');
      return
    }
    handleCancelVolume(rollbackData.id_request, rollbackData.id_product, reason)
  }
  return (

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box sx={styleRollbackConfirmModal} className='overflow-y-scroll variant-scroll'>
        <div className='flex justify-end -mt-5'>
          <IconButton onClick={handleClose} style={{ textAlign: 'right' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="p-6 text-center">
          <svg aria-hidden="true" className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>

          <h1 className='font-bold text-2xl'>Are you sure</h1>
          <p className='mb-3'>You will rollback the data</p>
          <p>Please input the reason</p>

          <select name="" id="" className='w-full rounded border px-2 py-1 mb-3' value={selectReason} onChange={e => {
            setSelectReason(e.target.value)
            if (e.target.value == "other") {
              setReason("")
            } else {
              setReason(e.target.value)
            }
          }}>
            <option value="" disabled>Select Option</option>
            {
              [
                "Product price is different",
                "Product volume is not correct",
                "Product weight is not correct",
                "Variant is not complete",
                "Product MOQ is not correct",
                "Product Cubic/Metre is not correct",
                "Product category is not correct",
                "other"
              ].map(v => (<option key={v} value={v}>{v}</option>))
            }
          </select>

          {
            selectReason == "other" && (
              <div className='mb-3'>
                <input value={reason} onChange={e => setReason(e.target.value)} type="text" className='w-full rounded border px-2 py-1' placeholder='Insert reason ...' />
                <p className='text-right'>{reason.length} / 100</p>
              </div>
            )
          }
          <button onClick={handleSubmit} data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
            Yes, I'm sure
          </button>
          <button onClick={handleClose} data-modal-hide="popup-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No, cancel</button>
        </div>
        <div className='text-center'>
        </div>
      </Box>
    </Modal>
  )
}

const HistoryModal = ({ open, handleClose, data }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box sx={styleHistoryModal} className='overflow-y-scroll variant-scroll'>
        <div className='flex justify-end -mt-5'>
          <IconButton onClick={handleClose} style={{ textAlign: 'right' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="p-6 text-center">
          <TableContainer
            component={Paper}
            className='table-scroll variant-scroll'
            sx={{ maxHeight: 450 }}>
            <Table
              stickyHeader
              sx={{ minWidth: 500 }}
              aria-label='custom pagination table'>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>User</TableCell>
                  <TableCell align='center'>Date</TableCell>
                  <TableCell align='center'>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(!data || data.length === 0) && (
                  <TableRow style={{ height: '30vh' }}>
                    <TableCell colSpan={13} align='center'>
                      No data available
                    </TableCell>
                  </TableRow>
                )}
                {data?.map((rowData, id) => (
                  <TableRow key={id}>
                    <TableCell align='center'>{rowData.user}</TableCell>
                    <TableCell align='center'>{moment(rowData.date).format('DD-MM-YYYY, h:mm:ss')}</TableCell>
                    <TableCell align='center'>{rowData.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
    </Modal>
  )
}