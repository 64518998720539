import { useState } from 'react';
import {
  CircularProgress,
  Box,
  IconButton,
  Modal,
  Fade,
  Backdrop,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getUser } from 'helpers/parseJWT';
import moment from 'moment';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 360,
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};

const FilterDate = ({
  date,
  setDate,
  setUpdate,
  page,
  setPage,
  filterWithSales,
}) => {
  const [openMonthPicker, setOpenMonthPicker] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState(moment().format('yyyy-MM'))
  const [loadingExport, setLoadingExport] = useState(false)
  
  const maxDate = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .split('T')[0];

  const handleChange = (event) => {
    const { value, name } = event.target;
    setDate({ ...date, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setUpdate((prev) => !prev);
    setPage(1);
  };

  const closeMonthPicker = () => {
    // setSelectedMonth(moment().format('yyyy-MM'))
    setOpenMonthPicker(false)
  }

  const exportToExcel = () => {
    if (['damai', 'mike', 'arif'].includes(getUser()?.user)) {
      setLoadingExport(true)
      const URL_DOWNLOAD = `${process.env.REACT_APP_URL_API_GATEWAY_NEW_GOLANG}/pre-sales/export/${selectedMonth}`;
      const tokenCookies = Cookies.get('oms_token');

      fetch(URL_DOWNLOAD, {
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${tokenCookies}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        } else {
          console.log(`failed export file : ${response?.status}`);
          closeMonthPicker()
        }
      })
      .then((data) => {
        if (data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          var a = document.createElement('a');
          a.href = window.URL.createObjectURL(data);
          a.download = `ALL_ORDERS_${selectedMonth}`;
          a.click();
        } else {
          toast.error('Failed to export file. Please try again.')
        }

        setLoadingExport(false);
        closeMonthPicker()
      });
    }
  };

  return (
    <div className="bg-white p-3 rounded-md mb-2 flex justify-between">
      <form onSubmit={handleSubmit} className="flex space-x-3 items-center">
        <p>Custom Filter : </p>
        {getUser().role === 'admin' && (
          <select
            onChange={handleChange}
            name="sales"
            value={date?.sales}
            className="w-40 border border-gray-300 p-1 rounded-md focus:outline-blue">
            <option value="">Select With sales</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
            {filterWithSales?.map((sales, index) => (
              <option key={index} value={sales}>
                {sales}
              </option>
            ))}
          </select>
        )}
        <input
          type="number"
          placeholder="ID Order"
          name="id"
          value={date?.id}
          onChange={handleChange}
          className="p-2 rounded-md w-28 border border-gray-200 focus:outline-blue"
        />
        <select
          name="status"
          onChange={handleChange}
          value={date?.status}
          className="border p-2 rounded-md focus:outline-none">
          <option value="">Choose Status</option>
          <option value="pending">Pending</option>
          <option value="unpaid">Unpaid</option>
          <option value="approval">Approval</option>
          <option value="paid">Paid</option>
        </select>
        <input
          type="text"
          placeholder="Start date"
          name="start"
          value={date?.start}
          max={maxDate}
          onChange={handleChange}
          className="p-2 rounded-md w-32 border border-gray-200 focus:outline-blue cursor-pointer"
          onFocus={(e) => (e.target.type = 'date')}
          onBlur={(e) => (e.target.type = 'text')}
        />
        <input
          type="text"
          name="end"
          className="p-2 rounded-md border border-gray-200 w-32 focus:outline-blue cursor-pointer"
          placeholder="End date"
          value={date?.end}
          max={maxDate}
          onChange={handleChange}
          onFocus={(e) => (e.target.type = 'date')}
          onBlur={(e) => (e.target.type = 'text')}
        />
        <button
          type="submit"
          className="bg-blue-300 rounded-md p-2 text-white hover:bg-blue-200 transition-all uppercase">
          Filter
        </button>
      </form>
      
      {['damai', 'mike', 'arief'].includes(getUser()?.user) &&
        <div
          className="bg-blue-500 rounded-md p-2 text-white font-bold hover:bg-blue-400 transition-all uppercase cursor-pointer"
          onClick={() => !loadingExport && setOpenMonthPicker(true)}
        >
          Download
        </div>
      }

      <Modal
        open={openMonthPicker}
        onClose={() => !loadingExport && closeMonthPicker()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openMonthPicker}>
          <Box sx={style}>
            {loadingExport ? 
                <div className="processing text-center">Exporting File...</div>
              :
              <>
                <div className="flex justify-between items-center -mt-5">
                  <div className="text-black font-bold text-2xl">Download File Excel</div>
                  <IconButton onClick={() => !loadingExport && closeMonthPicker()} style={{ textAlign: 'right' }}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="w-full flex flex-col gap-2 mb-3">
                  <label htmlFor="month-picker">Select Month</label>
                  <input type="month" name="month-picker" id="month-picker" 
                    className='border rounded-md px-1'
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)} />
                </div>
                <div
                  className="bg-blue-500 rounded-md p-2 text-white text-center text-sm font-bold hover:bg-blue-400 transition-all cursor-pointer"
                  onClick={exportToExcel}
                >
                  DOWNLOAD
                </div>
              </>
            }
          </Box>
        </Fade>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default FilterDate;
